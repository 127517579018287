import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";
//   <Route path={`${match.url}nouvelle_reparation`} component={asyncComponent(() => import('./B2B/ReparationDevis'))}/>
const AppB2B = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./B2B/DashboardPage'))}/>
      <Route path={`${match.url}commande_client`} component={asyncComponent(() => import('./B2B/ReparationPage'))}/>
      <Route path={`${match.url}facture`} component={asyncComponent(() => import('./B2B/FacturePageB2B'))}/>
      <Route path={`${match.url}achat_telephone`} component={asyncComponent(() => import('./B2B/AchatTelPage'))}/>
      <Route path={`${match.url}panier_achat`} component={asyncComponent(() => import('./B2B/PanierAchatPage'))}/>
      <Route path={`${match.url}ligne_mobile`} component={asyncComponent(() => import('./B2B/FlottePageB2B'))}/>
      <Route path={`${match.url}documents`} component={asyncComponent(() => import('./B2B/DocumentsPageB2B'))}/>
      <Route path={`${match.url}mon_compte`} component={asyncComponent(() => import('./B2B/ComptePageB2B'))}/>
      <Route path={`${match.url}dashboard_rse`} component={asyncComponent(() => import('./B2B/DashboardPageRSE'))}/>
      <Route path={`${match.url}collaborateur`} component={asyncComponent(() => import('./B2B/EmployePage'))}/>
      <Route path={`${match.url}parc`} component={asyncComponent(() => import('./B2B/ParcPageB2B'))}/>


    </Switch>
  </div>
);

export default AppB2B;
